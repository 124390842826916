@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div{
  display: block;
}

.nav-li{
  list-style-type: none;
  display: flex;
  padding-left: 30px;
  flex-wrap: wrap;
  align-content: center;
  position: relative;
  right: 0;
  font-size: 18px;
  font-weight: 300;
}
.nav-div{
  width: 100%;
  z-index: 999;
}
.main-appoint{
  grid-template-columns: 1fr 1fr;
}
.desc-img{
  width: 7rem;
  display: flex;
  justify-content: center;
  margin: auto;
}
.desc-text{
  padding: 2rem;
}
.book-li{
  margin-top: 2rem;
}
.about-us{
  background-image: url(./images/physioabout.jpg);
  background-size: cover;
  background-position: center;
  height: 35rem;
  margin-top: -1.5rem;
}
.img-feat{
  height: 100vh;
  background-image: url(./images/homeimg.jpg);
  background-size: cover;
  background-position: center;
  border-radius: 20px;
}
.img-main{
  height: 39rem;
}

@media not all and (min-width: 768px) {
  .nav-li{
    padding-left: 10px;
  }
  .hero-sec{
    display: block;
    height: auto;
  }
  .img-main{
    height: 31rem;
  }
  .quotes-sec{
    font-size: 30px;
    font-weight: 600;
  }
  .hero-box{
    padding: 2rem;
    padding-top: 12rem;
  }
  .about-cont{
    width: 20rem;
  }
  .appoint-box{
    margin-right: auto;
    padding: 10px;
  }
  .text-bookpage{
    /* display: none; */
    margin-left: auto;
    margin-right: auto;
  }
  .describe{
    display: block;
    padding: 0rem;
    padding-top: 3rem;
  }
  .book-div{
    display: block;
  }
}